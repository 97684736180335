/*
 * 업무구분: 위치정보 수집 및 이용 동의
 * 화 면 명: MSPGC102P
 * 화면설명: 위치정보 수집 동의
 * 접근권한: GFC
 * 작 성 일: 2022.06.21
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container title="위치정보 수집 및 이용 동의" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-agree-area">
        <p class="agree-title">내 주변 기업체 서비스 이용을 환영합니다.</p>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="gray-box">
          <p class="txtGray">위치 정보를 기반으로 현재 위치, 주변 컨텐츠 추천 및 광고 제공을 위해 이용자의 위치정보 및 사용 데이터가 필요합니다.
          <br><br>
          고객님께서 제공하시는 위치 정보는 현재 계신 위치에서 직선 거리 기준으로 가까운 기업체 위치 확인을 위해서만 이용됩니다</p>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="white-box">
          <p class="txtBlue">사랑On미니에서 내 기기 위치에 엑세스 하도록 허용하시겠습니까?</p>
          <p class="txtGray">- 제공하시는 위치정보는 컨설턴트님의 주변 기업 검색 서비스를 위해서만 활용되며 서비스 제공목적외 별도로 저장 및 사용하지 않습니다.</p>
          <p class="txtBlack">- 위치정보가 표시되지 않을 시 휴대폰 설정에서 위치정보 사용권한을 변경 후 시도해 주시기 바랍니다.</p>
          <p class="txtGray ns-style2">※ <b>설정 → 애플리케이션 → 사랑On미니 → 권한 → 위치 → 앱사용 중에서만 허용</b></p>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="onNegative">동의 안함</mo-button>
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="onPositive">동의</mo-button>
          </div>
        </ur-box-container>
        <div class="ns-height100"></div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPGC102P",
    screenId: "MSPGC102P",
    props: {

    },
    components: {
      
    },

    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
   
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isMngr: false, // 지점장 여부
        authCd: 'U000', // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
      };
    },

    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/

    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created () {
      
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************
       * Function명  : onPositive
       * 설명        : fdpConfirm 예 버튼 클릭 시, 콜백 함수
       ******************************************************/
      onPositive () {
        window.vue.getStore('spStore').dispatch('IS_NEARBY_AGREE', true )
        this.$emit('onPositive')
      },
      
      /*******************************************************
       * Function명  : onPositive
       * 설명        : fdpConfirm 아니오 버튼 클릭 시, 콜백 함수
       ******************************************************/
      onNegative () {
        window.vue.getStore('spStore').dispatch('IS_NEARBY_AGREE', false )
        this.$emit('onNegative')
      },

      /*****************************************
       * Function명  : fn_Back
       * 설명        : 뒤로가기 (history back)
       *****************************************/
      fn_Back () {
        this.$emit('onNegative')
      }
    }
  };
</script>
<style scoped>
</style>