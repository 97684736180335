export default Object.freeze({
  // AUTH_SEARCH: 'S'


  // 한국 지역 좌표 데이터
  KOR_CRDN_DATA: [
    {'dstrt': '서울특별시', 'dstrtCd': '01', 'CrdnDstVal': '0.6', 'westLtt': '126.764879', 'eastLtt': '127.176790', 'northLgt': '37.701186', 'sthLgt': '37.428914'},
    {'dstrt': '경기도', 'dstrtCd': '02', 'CrdnDstVal': '1', 'westLtt': '127.090587', 'eastLtt': '127.288200', 'northLgt': '37.763087', 'sthLgt': '37.551708'},
    {'dstrt': '인천광역시', 'dstrtCd': '03', 'CrdnDstVal': '1', 'westLtt': '126.084847', 'eastLtt': '126.793604', 'northLgt': '37.831593', 'sthLgt': '37.209510'},
    {'dstrt': '부산광역시', 'dstrtCd': '04', 'CrdnDstVal': '1', 'westLtt': '128.798581', 'eastLtt': '129.251432', 'northLgt': '35.380254', 'sthLgt': '34.988919'},
    {'dstrt': '대구광역시', 'dstrtCd': '05', 'CrdnDstVal': '1', 'westLtt': '128.377840', 'eastLtt': '128.694491', 'northLgt': '36.011173', 'sthLgt': '35.608006'},
    {'dstrt': '대전광역시', 'dstrtCd': '06', 'CrdnDstVal': '1', 'westLtt': '127.334857', 'eastLtt': '127.383788', 'northLgt': '36.398461', 'sthLgt': '36.290901'},
    {'dstrt': '세종특별시', 'dstrtCd': '07', 'CrdnDstVal': '1', 'westLtt': '127.128741', 'eastLtt': '127.352441', 'northLgt': '36.731642', 'sthLgt': '36.407888'},
    {'dstrt': '광주광역시', 'dstrtCd': '08', 'CrdnDstVal': '1', 'westLtt': '126.807501', 'eastLtt': '126.911079', 'northLgt': '35.256535', 'sthLgt': '35.051757'},
    {'dstrt': '울산광역시', 'dstrtCd': '09', 'CrdnDstVal': '1', 'westLtt': '128.979414', 'eastLtt': '129.465191', 'northLgt': '35.723824', 'sthLgt': '35.324870'},
    {'dstrt': '제주도', 'dstrtCd': '10', 'CrdnDstVal': '1', 'westLtt': '126.268394', 'eastLtt': '126.773622', 'northLgt': '33.513765', 'sthLgt': '33.311575'}
  ]
})
