/*
 * 업무구분: 내 주변 기업체
 * 화 면 명: MSPGC101M
 * 화면설명: GPS 정보이용 주변 기업체 조회
 * 접근권한: GFC
 * 작 성 일: 2022.06.21
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container :show-title="true" title="내 주변 기업체" type="subpage" action-type="none" @on-scroll-bottom="fn_LoadingData()">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-info-sum" >
          <mo-list-item>
            <div class="list-item__contents ns-type-area">
              <div class="list-item__contents__title ns-type-left">
                <span class="sum" v-if="gckedEntpListSVO.length > 0">조회 {{totCnt}}건</span>
                <span class="sum" v-else>조회 0건</span>
              </div>
              <div class="list-item__contents__title ns-type-right "> 
                <div class="ns-check"><mo-checkbox v-model="lv_Checked" @input="fn_onSerNoContList(lv_Checked)" >미등록</mo-checkbox></div>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- <div v-if="gckedEntpListSVO.length > 0"> fdf -->
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list ns-accodian-mode" v-if="gckedEntpListSVO.length > 0">
            <mo-list :list-data="gckedEntpListSVO" id="scroll">
              <template #list-item="{item, index}" >   
                <mo-list-item expandable prevent-expand :ref="'accordion' + index" @on-expand-click="clickedAccordion(item, index)">
                  <div class="list-item__contents">
                    <div class="item-wrapper__row">
                      <div style="width: 100%;">
                        <div class="list-item__contents__title">
                          <div class="badge-title">
                            <mo-badge class="badge-sample-badge blue" text="정상" shape="status">{{fn_typeOfCompany(item.bmanNo)}}</mo-badge>
                            <span class="name">
                              {{item.whlComyNm}}
                            </span>
                          </div>
                          <span class="right-txt blue">{{item.custCrdnDstVal}}Km</span>
                        </div>
                        <div class="list-item__contents__info color">
                          <span class="txtSkip">{{item.stnrdIndsIndclCdNm}}</span>
                        </div>
                        <div class="list-item__contents__info">
                          <span>{{item.lctnRoadNmPstAddr}} {{item.lctnRoadNmDtladEncr}} (우){{item.lctnPstcd}}</span>
                          <mo-button class="map-icon mo-button" @click="fn_openComfirmPop(item.whlComyNm,item.lttudFgr,item.lgtudFgr)">지도</mo-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-slot:expand class="ns-gray-box">
                    <div class="list-item__contents">
                      <div class="list-item-detail" style="">
                        <ul>
                          <li>
                            <div class="title-cell">
                              <strong>전화번호</strong>
                            </div> 
                            <div class="value-cell" v-if="$bizUtil.isEmpty(item.hdofTelno)">
                              <span>없음</span>
                            </div>
                            <div class="value-cell" v-else>
                              <span>{{$bizUtil.telNoWithHyphen(item.hdofTelno, 1)}}</span>
                            </div>
                          </li> 
                          <li>
                            <div class="title-cell">
                              <strong>사업자번호</strong>
                            </div> 
                            <div class="value-cell" v-if="$bizUtil.isEmpty(item.bmanNo)">
                              <span>없음</span>
                            </div>
                            <div class="value-cell" v-else>
                              <span><b>{{fn_PlaceHolder(item.bmanNo, 1)}}</b></span>
                              <span>(대표) {{item.prsName}}</span>
                            </div>
                          </li> 
                          <li>
                            <div class="title-cell">
                              <strong>설립일자</strong>
                            </div> 
                            <div class="value-cell" v-if="$bizUtil.isEmpty(item.corpEstbYmd)">
                              <span>없음</span>
                            </div>
                            <div class="value-cell" v-else>
                              <span>{{dateYyMmDdFormat(item.corpEstbYmd)}}</span>
                            </div>
                          </li> 
                          <li>
                            <div class="title-cell">
                              <strong>종업업수</strong>
                            </div> 
                            <div class="value-cell" v-if="$bizUtil.isEmpty(item.alwyLbrrFgr) || item.alwyLbrrFgr < 1">
                              <span class="value-cell">없음</span>
                            </div> 
                            <div class="value-cell" v-else>
                              <span class="value-cell">{{item.alwyLbrrFgr}}</span>
                            </div> 
                          </li>
                          <li>
                            <div class="title-cell">
                              <strong>당사등록여부</strong>
                            </div> 
                            <div class="value-cell" v-if="item.hasGrpContList === '1'">
                              <span class="value-cell txtRed">당사 등록</span>
                            </div> 
                            <div class="value-cell" v-else-if="item.hasGrpContList === '-1'">
                              <span class="value-cell txtRed">당사 미등록</span>
                            </div> 
                            <div class="value-cell" v-else>
                              <span class="value-cell txtRed">-</span>
                            </div> 
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        <!-- </div> -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="no-data" v-else-if="gckedEntpListSVO.length === 0 && isAfterFirstSearch === true">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 존재하지 않습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/

  import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
  import FrameEventBus from '~/src/ui/_cmm/FrameEventBus.js'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import GcConstants from '@/config/constants/gcConstants'
  //import scrollWrapper from '@/ui/_cmm/components/scroll-wrapper'
  //import TextFieldWrapper from '@/ui/_cmm/components/TextFieldWrapper'
  import MSPGC102P from '@/ui/gc/MSPGC102P'

  export default {

    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPGC101M",
    screenId: "MSPGC101M",
    props: {

    },
    components: {
      MSPGC102P
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isAfterFirstSearch: false,
        isScrolled: false,
        // 누적 총 조회건수
        totCnt: 0,
        // 더보기 key
        stndKeyList: [],
        stndKeyVal: 'start',
        lv_IsLoadingStatus: false,
        // 바인딩 리스트VO
        gckedEntpListSVO: [],
        originGckedEntpListSVO: [],
        gckedEntpListTempSVO: [],
        // 로그인한 컨설턴트번호
        lv_cnsltNo: '',
        // 카카오맵 연동 확인 팝업
        lv_BConfirm: false,
        lv_firstCnfm: false,
        // 사용자 현위치 위도
        curLtt: '',
        // 사용자 현위치 경도
        curLgt: '',
        // 기업체 이름
        toNm: '',
        // 기업체 위도
        toLtt: '',
        // 기업체 경도
        toLgt: '',
        // 기업체 검색 반경 default: 500m
        custCrdnDstVal: '5',
        // 체크박스 클릭여부
        lv_Checked: false,

        // nextKey방식의 페이징 변수 (※지우면 페이징 처리 안됨 주의!!※)
        lv_ClsfSymtCntntStndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
        lv_ClsfSymtCntntPageRowCnt: '',
        pageRowCnt: 10,
        idx: 0,
        accodionKey: '',
        // 한국 지역 좌표 데이터
        KorCrdnData: []
      };
    },


    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created () {
      this.userInfo = this.getStore('userInfo').getters.getUserInfo
      this.lv_cnsltNo = this.userInfo.userId
      // 지역별 범위
      this.KorCrdnData = GcConstants.KOR_CRDN_DATA
    },

    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPGC101M')

      // 더보기 적용 
      // FrameEventBus.$on('on-scroll-bottom', (e) => {
      //   this.fn_LoadingData()
      // })
      this.fn_SubmitS()
    },

    /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
    beforeDestroy () {
      FrameEventBus.$off('on-scroll-bottom');
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /************************************************************************************************
       * Function명 : onScroll
       * 설명       : this.scrollTop 값을 갱신
       *              (스크롤이 움직이는 순간 호출)
       * @param {Number} scrollTop - 엘리먼트 scrollLeft 값
       * @return {none} - 반환없음
       ************************************************************************************************/
      // onScroll (event, scrollTop) {
      //   this.isScrolled = scrollTop > 0
      // },

      /************************************************************************************************
       * Function명 : fn_SubmitS
       * 설명       : 주변 기업체 조회 시작 함수
       ************************************************************************************************/
      fn_SubmitS () {
        this.totCnt = 0
        this.gckedEntpListSVO = [] // 인피니트 바인딩 데이터
        this.stndKeyList = []
        this.gckedEntpListSVO.splice(0, this.gckedEntpListSVO.length)

        if (window.vue.getStore('spStore').getters.getState.isNearByAgree) {
          // 이미 동의 받은경우
          this.fn_getLocation()
        } else {
          // 미동의 경우
          this.agreePop = this.$bottomModal.open(MSPGC102P, {
          
            listeners: {
              onPositive: () => {
                this.$bottomModal.close(this.agreePop);
                window.vue.getStore('gcStore').dispatch('IS_NEARBY_AGREE', true )
                this.fn_getLocation()
              },
              onNegative: () => {
                this.$bottomModal.close(this.agreePop);
                window.vue.getStore('gcStore').dispatch('IS_NEARBY_AGREE', false )
                this.$router.go(-1)
              }
            }
          });
        }
      },

      /************************************************************************************************
       * Function명: fn_LoadingData
       * 설명: 데이터 로딩
       ************************************************************************************************/
      fn_LoadingData () {
        if ( !this.lv_Checked ) {
          this.lv_IsLoadingStatus = true
          this.lv_Checked = false
          this.fn_getCrdnDstVal()
          this.fn_RetrieveUserData()
        }
      },

      /************************************************************************************************
       * Function명: fn_getCrdnDstVal
       * 설명: 현위치 좌표로 지역파악 후, 검색 반경 셋팅
       ************************************************************************************************/
      fn_getCrdnDstVal () {
        let lv_vm = this
        let rtnItem = []
        let rtnValu = ''

        // 현위치 좌표로 지역데이터 필터
        rtnItem = lv_vm.KorCrdnData.filter((value) => {
          if ( value.westLtt < lv_vm.curLgt && lv_vm.curLgt < value.eastLtt ) {
            if ( value.sthLgt < lv_vm.curLtt && lv_vm.curLtt < value.northLgt ) {
              return value
            }
          }
        })

        // 현위치 경기권일때 서울 제외
        if (rtnItem.length > 1) {
          rtnItem = rtnItem.filter((value) => {
            return value.dstrtCd === '02'
          })
        }

        if (!this.$bizUtil.isEmpty(rtnItem) && rtnItem.length > 0) {
          rtnValu = rtnItem[0].CrdnDstVal // 지역별 검색 반경값
        } else {
          rtnValu = '5'
        }
        this.custCrdnDstVal = rtnValu
      },

      /************************************************************************************************
       * Function명 : fn_RetrieveUserData
       * 설명 : 주변기업체 조회 서비스 함수
       ************************************************************************************************/
      fn_RetrieveUserData () {
        if (this.stndKeyVal === 'end') return Promise.resolve()
        const auth = 'S'
        let lv_vm = this
        let trnstId = 'txTSSGC03S1' // 트랜잭션Id
        lv_vm.isAfterFirstSearch = false

        let GCKedEntpPstnListSVO = {
          hdofAddr: '', // setFnctScCd("S1") 용도
          lttudFgr: '',
          lgtudFgr: '',
          custCrdnDstVal: lv_vm.custCrdnDstVal // 현위치 반경 default: 500m
        }
        GCKedEntpPstnListSVO.lttudFgr = this.curLtt
        GCKedEntpPstnListSVO.lgtudFgr = this.curLgt
        let pParams = GCKedEntpPstnListSVO

        this.post(lv_vm, pParams, trnstId, auth)
          .then(async function (response) {
            lv_vm.isAfterFirstSearch = true
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
              let responseList = response.body

              lv_vm.gckedEntpListTempSVO = responseList.gCKedEntpPstnVO

              if (!lv_vm.$bizUtil.isEmpty(lv_vm.gckedEntpListTempSVO)) {
                for (let i = 0; i < lv_vm.gckedEntpListTempSVO.length; i++) {
                  lv_vm.$set(lv_vm.gckedEntpListTempSVO[i], 'checked', false)
                  lv_vm.$set(lv_vm.gckedEntpListTempSVO[i], 'idx', lv_vm.idx.toString())
                  lv_vm.$set(lv_vm.gckedEntpListTempSVO[i], 'openedDetail', false)
                  lv_vm.$set(lv_vm.gckedEntpListTempSVO[i], 'rsnDtlCntnt', '')
                  if (lv_vm.$bizUtil.isEmpty(lv_vm.gckedEntpListTempSVO[i].kisCd)) {
                    lv_vm.$set(lv_vm.gckedEntpListTempSVO[i], 'hasGrpContList', '-1') // hasGrpContList가 -1 일경우 고객이 아니며 계약이 없음 //1일경우 당사고객이며 계약이 존재함
                  } else {
                    lv_vm.$set(lv_vm.gckedEntpListTempSVO[i], 'hasGrpContList', '1')
                    // lv_vm.$set(lv_vm.gckedEntpListTempSVO[i], 'hasGrpContList', await lv_vm.fn_GetService(lv_vm.gckedEntpListTempSVO[i].kisCd))
                  }
                  lv_vm.totCnt = lv_vm.totCnt + 1
                  lv_vm.idx = lv_vm.idx + 1

                  lv_vm.originGckedEntpListSVO.push(lv_vm.gckedEntpListTempSVO[i]) // 원본 데이터
                }
                lv_vm.gckedEntpListSVO = lv_vm.originGckedEntpListSVO // 화면 바인딩 데이터
              }

              // 더보기 키값
              lv_vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_vm.stndKeyList === null || lv_vm.stndKeyList.length === 0 || lv_vm.stndKeyList[0] === null || lv_vm.stndKeyList[0].stndKeyVal === null || lv_vm.stndKeyList[0].stndKeyVal.trim() === '') {
                lv_vm.stndKeyVal = 'end'
              } else {
                lv_vm.stndKeyVal = 'more'
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          }).finally(() => {
            this.lv_IsLoadingStatus = false
          })
      },

      /************************************************************************************************
       * Function명 : fn_typeOfCompany
       * 설명       : 개인인지 법인인지 구분
       ************************************************************************************************/
      fn_typeOfCompany (bno) {
        let bsnno02 = bno.substring(3, 5)
        if (bsnno02 >= 81 && bsnno02 <= 88 ) {
          return '법인 '
        } else {
          return '개인'
        }
      },

      /************************************************************************************************
       * Function명 : dateYyMmDdFormat
       * 설명       : 날짜 형식 년월 표시 ( YYYYMMDD => YYYY-MM-DD )
       * @param {String} value : 날짜 형식 변경
       ************************************************************************************************/
      dateYyMmDdFormat (value) {
        if (!value) return ''
        value = this.$bizUtil.insertString(this.$bizUtil.insertString(value, 6, '-'), 4, '-')
        return value
      },

      /************************************************************************************************
       * Function명 : clickedAccordion
       * 설명       : 고객상세 아코디언 버튼 클릭 이벤트 핸들러
       ************************************************************************************************/
      clickedAccordion (childObj, idx) {
        childObj.openedDetail = !childObj.openedDetail
        this.accodionKey = 'accordion' + idx

        if (childObj.openedDetail === true) {
          this.$refs[this.accodionKey].expand()
          if(!this.$bizUtil.isEmpty(childObj.kisCd) && childObj.hasGrpContList === '0'){
            this.fn_GetService(childObj) // 상세조회 서비스 호출
          }
        }else{
          this.$refs[this.accodionKey].collapse()
        }

      },

      /************************************************************************************************
       * Function명 : fn_GetService
       * 설명       : 보유단체계약 목록 조회 서비스 호출
       * hasGrpContList: 당사 가입 여부
       ************************************************************************************************/
      async fn_GetService (kisCd) {
        const trnstId = 'txTSSGP01S1'
        const auth = 'S'
        let inputParams = {}
        inputParams.cnsltNo = this.lv_cnsltNo
        inputParams.bpId = kisCd
        let returnGrpContList = '-1'
        let lv_vm = this
        let pParams = inputParams

        returnGrpContList = await this.post(lv_vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response && response.body) {
              if (response.body.zSFPC77051VO) {
                // 보유건수가 0인 데이터는 제외 시킨다 ??
                if (response.body.zSFPC77051VO && response.body.zSFPC77051VO.length > 0) {
                  return '1' // 계약이 하나라도 존재할 경우
                } else {
                  return '-1' // 계약이 없을 때
                }
              } else {
                return '-1' // 검색결과가 존재하지 않습니다.
              }
            } else {
              return '-1' // 검색결과가 존재하지 않습니다.
            }
          
          })
          .catch(function (error) {
            window.vue.error(error)
          })
        return returnGrpContList
      },

    

      /************************************************************************************************
       * 설명: 스크롤 관련 함수들
       *****************************/
      scrollTo (scrollTop) {
        if (this.scrollAnimateY.isScrolling()) {
          return
        }
        this.scrollAnimateY.animate(scrollTop, 700, 0, 'easeOut')
      },

      // createAnimate (keyword, parentEl) {
      //   if (!keyword || !parentEl) {
      //     throw new Error('invali param')
      //   }
      //   const span = document.createElement('span')
      //   span.innerText = keyword
      //   span.className = '-pub-initial-search__current-keyword'
      //   parentEl.appendChild(span)
      //   setTimeout(_ => {
      //     try {
      //       span.parentElement.removeChild(span)
      //     } catch (e) {
      //       throw new Error('remove Failed')
      //     }
      //   }, 1000)
      // },

      /************************************************************************************************
       * Function명: fn_openComfirmPop
       * 설명: 카카오맵으로 연동할지 물어보는 팝업
       * @param => nm: 기업이름, lttud: 위치위도, lgtud: 위치경도
       ************************************************************************************************/
      fn_openComfirmPop ( nm, lttud, lgtud ) {
        // 카카오맵 연동 여부 확인 한번만(매번 팝업 띄우는거 방지)
        if (!this.lv_firstCnfm) {
          this.bottomAlert()
        } else {
          this.fn_ConfirmProcess('Y')
        }

        this.toNm = nm
        this.toLtt = lttud
        this.toLgt = lgtud
      },

      /************************************************************************************************
       * Function명: fn_ConfirmProcess
       * 설명: 확인처리 프로세스
       * @param data : Y/N (카카오맵 연동 확인 팝업에서 "예" 클릭시 실행)
       * toNm: 기업이름, toLtt: 위치위도, toLgt: 위치경도
       ************************************************************************************************/
      fn_ConfirmProcess ( data ) {
        if ( data === 'N' ) {
          return null
        } else {
          this.lv_firstCnfm = true
          // 카카오맵으로 이동하는 기능 추가(앱개발자)
          let lv_Url = 'https://map.kakao.com/link/to/' + this.toNm + ',' + this.toLtt + ',' + this.toLgt + '/from/현재위치,' + this.curLtt + ',' + this.curLgt

          if (this.$commonUtil.isMobile()) { // 모바일에서 작동
            window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
          } else { // PC에서 작동
            window.open(lv_Url, '_blank')
          }
          return null
        }
      },

      /************************************************************************************************
       * Function명: fn_PlaceHolder
       * 설명: 사업자등록번호, 법인번호 출력 포맷 ( null 체크생략 null 경우 승인요청불가 )
       ************************************************************************************************/
      fn_PlaceHolder ( pNo, pType ) {
        if ( pType === 1 ) { // 사업자등록번호
          return pNo.replace(/([0-9]{3})([0-9]{2})([0-9]{5})/, '$1-$2-$3')
        } else if ( pType === 2 ) { // 법인번호
          return pNo.replace(/([0-9]{6})([0-9]{7})/, '$1-$2')
        } else {
          return pNo
        }
      },

      /************************************************************************************************
       * Function명: fn_msgLocation
       * 설명: 프로그레스바 비활성화하며, 주변 기업체 검색 함수 호출
       ************************************************************************************************/
      fn_msgLocation () {
        this.getStore('progress').getters.getState.isShow = false
        this.fn_getCrdnDstVal()
        this.fn_RetrieveUserData()
      },

      /************************************************************************************************
       * Function명: fn_getLocation
       * 설명: 현위치 좌표 받기
       ************************************************************************************************/
      fn_getLocation () { 
        this.getStore('progress').getters.getState.isShow = true // 프로그레스바 활성화
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => { // success
            this.curLtt = position.coords.latitude
            this.curLgt = position.coords.longitude
            this.fn_msgLocation()
          }, (error) => { // failure
            window.vue.error(error)

            // default 값 설정 "삼성생명 서초타워"
            this.curLtt = '37.496688'
            this.curLgt = '127.025701'
            this.fn_msgLocation()
            // // default 값 설정
          }, { // option
            timeout: 50 * 1000 // 1초 기준
          })
        }
      },

      /************************************************************************************************
       * Function명: fn_onSerNoContList
       * 설명: 가입/미가입 데이터 필터
       ************************************************************************************************/
      fn_onSerNoContList (chek) {
        let lv_vm = this
        if (chek) {
          let filteredGckedEntpList = []
          filteredGckedEntpList = lv_vm.originGckedEntpListSVO.filter((value) => {
            if (value.hasGrpContList === '-1') {
              return value
            }
          })
          this.totCnt = filteredGckedEntpList.length
          lv_vm.gckedEntpListSVO = filteredGckedEntpList
          //this.scrollTo(0) // pk.son
        } else {
          this.totCnt = lv_vm.originGckedEntpListSVO.length
          lv_vm.gckedEntpListSVO = lv_vm.originGckedEntpListSVO
        }
      },
      bottomAlert() {
        this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "알림",
            content: "사랑On(미니)에서 \n'카카오맵'을(를) 열려고 합니다.",
            title_pos_btn: "열기",
            title_neg_btn: "취소"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.alertPop);
              this.fn_ConfirmProcess('Y')
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.alertPop);
              this.fn_ConfirmProcess('N')
            }
          }
        });
      }  
    }
  };
</script>
<style scoped>
</style>